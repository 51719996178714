import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import * as React from "react"
import ArticleCard from "../components/article-card"
import H2 from "../components/h2"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Articles() {
  const data = useStaticQuery(graphql`
    query articlesQuery {
      allContentfulArticle {
        nodes {
          title
          url
          heroImage {
            gatsbyImageData
          }
          tags
          minutesToRead
        }
      }
    }
  `)
  console.debug("Articles Data: ", data)

  const articles = data.allContentfulArticle.nodes.map(node => {
    return {
      title: node.title,
      image: getImage(node.heroImage),
      url: `/articles/${node.url}`,
      minutesToRead: node.minutesToRead,
      tags: node.tags,
    }
  })

  return (
    <Layout>
      <Seo title="Articles" />
      <div className="py-8 px-8 lg:px-16">
        <H2>Articles</H2>
        <div className="py-8 grid grid-cols-1 lg:grid-cols-2 w-full lg:w-3/4 justify-center gap-y-8 gap-x-8">
          {articles.map((article, index) => (
            <ArticleCard article={article} key={index} />
          ))}
        </div>
      </div>
    </Layout>
  )
}
